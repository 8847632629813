import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "@store/rootReducer"
import { categoriesAdapter, templatesAdapter } from "./reducer"

export const selectCategoriesState = (state: RootState) => state.occassions.categories
export const categoriesSelector = categoriesAdapter.getSelectors(selectCategoriesState)
export const selectCategories = categoriesSelector.selectAll
export const selectCategoryById = categoriesSelector.selectById

export const selectSelectedCategoryId = (state: RootState) => state.occassions.selectedCategory.id

export const selectSelectedCategory = createSelector(
  (s) => s,
  selectSelectedCategoryId,
  categoriesSelector.selectById
)

export const selectTemplatesState = (state: RootState) => state.occassions.templates
export const templatesSelector = templatesAdapter.getSelectors(selectTemplatesState)
export const selectTemplates = templatesSelector.selectAll

export const selectUpdatedAt = (state: RootState) => state.occassions.updatedAt
