import { useEffect } from "react"
import toast, { Toaster } from "react-hot-toast"
import { useSelector } from "react-redux"
import { useThemeUI } from "theme-ui"
import { selectNotification } from "./store/slices/feedback/selectors"

function NotificationsManager() {
  const notification = useSelector(selectNotification)
  const themeui = useThemeUI()
  useEffect(() => {
    if (notification.message) {
      toast(notification.message, {
        style: {
          background: themeui.theme.colors?.background as string,
          color: themeui.theme.colors?.text as string,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])
  return <Toaster />
}

export default NotificationsManager
