import { Flex } from "theme-ui"
import Navbar from "@components/Navbar"
import CompanyDetails from "./CompanyDetails"
import UserDetails from "./UserDetails"

function Settings() {
  return (
    <Flex
      sx={{
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <Flex sx={{ flex: 1 }}>
        <Flex
          sx={{
            flex: 1,
            maxWidth: "1140px",
            margin: "0 auto",
            flexDirection: ["column", "column", "column", "row"],
          }}
        >
          <CompanyDetails />
          <UserDetails />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Settings
