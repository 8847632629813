import { Company } from "@common/interfaces"
import { createReducer, current } from "@reduxjs/toolkit"
import {
  setCompany,
  setCompanyGLWidgetID,
  setCompanyOrigins,
  setEnabledCompanyId,
  setPrice,
  setUpdatedAt,
  unsetCompany,
} from "./actions"

export interface DetailsState {
  company: Partial<Company> | null
  enabledCompanyId: number
  updatedAt: number | null
  glWidgetID: string
  price: string
}

const initialState: DetailsState = {
  company: null,
  enabledCompanyId: 0,
  updatedAt: null,
  glWidgetID: "",
  price: "",
}

export const detailsReducer = createReducer(initialState, (builder) => {
  builder.addCase(unsetCompany, (state) => {
    state.company = null
    state.updatedAt = null
  })
  builder.addCase(setCompany, (state, { payload }) => {
    const currentState = current(state)
    state.company = currentState.company ? { ...currentState.company, ...payload } : payload
  })
  builder.addCase(setCompanyOrigins, (state, { payload }) => {
    state.company!.origins = payload
  })

  builder.addCase(setEnabledCompanyId, (state, { payload }) => {
    state.enabledCompanyId = payload
  })

  builder.addCase(setPrice, (state, { payload }) => {
    state.price = payload
  })
  builder.addCase(setUpdatedAt, (state, { payload }) => {
    state.updatedAt = payload
  })

  builder.addCase(setCompanyGLWidgetID, (state, { payload }) => {
    state.glWidgetID = payload
  })
})
