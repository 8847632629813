import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { selectToken } from "@store/slices/auth/selectors"
import { signOut } from "@store/slices/auth/actions"
import addToSentry from "@/utils/sentry"

const blackListUrls = ["/giftlift/registrations", "/giftlift/sessions"]

export function useTokenInterceptor() {
  const token = useSelector(selectToken)
  const history = useHistory()
  const dispatch = useDispatch()
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!isLoaded) {
      setLoaded(true)
    }
    const authInterceptor = axios.interceptors.request.use((request) => {
      if (
        token &&
        request.url &&
        request.url.includes("/api") &&
        !blackListUrls.includes(request.url)
      ) {
        request.headers.Authorization = `Bearer ${token}`
      }

      return request
    })

    return () => {
      axios.interceptors.request.eject(authInterceptor)
    }
  }, [token, isLoaded])

  useEffect(() => {
    const authInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          dispatch(signOut())
          history.push("/sign-in")
        } else {
          addToSentry(error)
        }
        return Promise.reject(error)
      }
    )

    return () => {
      axios.interceptors.response.eject(authInterceptor)
    }
  }, [history, dispatch])

  return isLoaded
}
