import { Flex } from "theme-ui"
import Navbar from "@components/Navbar"

import useAppContext from "@/hooks/useAppContext"
import Mobile from "./Mobile"
import Web from "./Web"

function Occasions() {
  const { isMobile } = useAppContext()
  return (
    <Flex
      sx={{
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <Flex sx={{ flex: 1 }}>{isMobile ? <Mobile /> : <Web />}</Flex>
    </Flex>
  )
}

export default Occasions
