import { Input, Button, Flex, Label, Text } from "theme-ui"
import Link from "@components/Link/Link"
import { ResetPasswordDto } from "@/common/interfaces"
import { FormEvent } from "react"

interface PasswordFormProps {
  params: ResetPasswordDto
  setParams: React.Dispatch<React.SetStateAction<ResetPasswordDto>>
  onSubmit: (e: FormEvent) => void
}
function PasswordForm({ params, setParams, onSubmit }: PasswordFormProps) {
  return (
    <Flex
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Flex
        as="form"
        sx={{
          maxWidth: "420px",
          width: "100%",
          padding: "2rem",
          flexDirection: "column",
        }}
        onSubmit={onSubmit}
      >
        <Text as="h2" mb={4} sx={{ textAlign: "center" }}>
          Reset Password
        </Text>

        <Label htmlFor="newPassword">New Password</Label>
        <Input
          onChange={(e) => setParams({ ...params, pw: e.target.value })}
          name="newPassword"
          id="newPassword"
          mb={3}
          mt={2}
          type="password"
        />

        <Label htmlFor="email">Confirm Password</Label>
        <Input
          onChange={(e) => setParams({ ...params, pw_confirm: e.target.value })}
          name="email"
          id="email"
          mb={3}
          mt={2}
          type="password"
        />

        <Button sx={{ height: "54px" }} mt={2}>
          Reset
        </Button>
        <Link variant="button" to="/sign-in">
          Cancel
        </Link>
      </Flex>
    </Flex>
  )
}

export default PasswordForm
