import { FC } from "react"
import { ThemeProvider } from "theme-ui"
import store, { persistor } from "@store/store"
import { PersistGate } from "redux-persist/integration/react"
import { Provider } from "react-redux"
import theme from "./theme"
import { AppProvider } from "./contexts/AppContext"

const Providers: FC = ({ children }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <AppProvider>{children}</AppProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
)

export default Providers
