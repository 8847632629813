import { Category, Template } from "@common/interfaces"
import { createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit"
import {
  setCategories,
  setCategorySelectedId,
  setTemplates,
  setUpdatedAt,
  updateOneCategory,
  updateOneTemplate,
} from "./actions"

export const categoriesAdapter = createEntityAdapter<Category>({
  selectId: ({ id }) => id,
})

export const templatesAdapter = createEntityAdapter<Template>({
  selectId: ({ id }) => id,
})

export interface occasionsState {
  categories: EntityState<Category>
  templates: EntityState<Template>
  selectedCategory: SelectedCategory
  selectedTemplate: SelectedTemplate
  updatedAt: number | null
}

export interface SelectedCategory {
  id: number
  loading: boolean
}

export interface SelectedTemplate {
  id: number | null
  loading: boolean
}

const initialState: occasionsState = {
  updatedAt: null,
  categories: categoriesAdapter.getInitialState(),
  templates: templatesAdapter.getInitialState(),
  selectedCategory: {
    id: 0,
    loading: false,
  },
  selectedTemplate: {
    id: null,
    loading: false,
  },
}

export const occasionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setCategories, (state, { payload }) => {
    categoriesAdapter.setAll(state.categories, payload)
  })
  builder.addCase(updateOneCategory, (state, { payload }) => {
    categoriesAdapter.updateOne(state.categories, payload)
  })

  builder.addCase(setCategorySelectedId, (state, { payload }) => {
    state.selectedCategory.id = payload
  })
  builder.addCase(setTemplates, (state, { payload }) => {
    templatesAdapter.setAll(state.templates, payload)
  })
  builder.addCase(updateOneTemplate, (state, { payload }) => {
    templatesAdapter.updateOne(state.templates, payload)
  })

  builder.addCase(setUpdatedAt, (state, { payload }) => {
    state.updatedAt = payload
  })
})
