import { createAsyncThunk, createAction } from "@reduxjs/toolkit"
import {
  getCurrentCompany,
  setCompany,
  setUpdatedAt,
  unsetCompany,
} from "@store/slices/details/actions"
import { setErrors, setNotification } from "@store/slices/feedback/actions"
import {
  ResetPasswordDto,
  SignInDto,
  SignUpDto,
  UpdateObjectDto,
  UpdatePasswordDto,
  User,
} from "@common/interfaces"
import { PasswordChangeStatus } from "@common/constants"
import api from "@services/api"
import { getCurrentDate } from "@/utils/date"
import { AxiosError } from "axios"

// Actions

export const setAuthenticated = createAction<boolean>("auth/setAuthenticated")
export const setToken = createAction<string>("auth/setToken")
export const removeAuth = createAction("auth/removeAuth")

export const setPasswordChangeStatus = createAction<PasswordChangeStatus>(
  "auth/setPasswordChangeStatus"
)
export const setUser = createAction<Partial<User>>("auth/setUser")

// Thunks

export const signIn = createAsyncThunk<void, SignInDto, any>(
  "auth/signIn",
  async (args, { dispatch }) => {
    try {
      const response = await api.signIn(args)
      dispatch(setAuthenticated(true))
      dispatch(setToken(response.token))
      dispatch(setUser(response.user))
      dispatch(setCompany(response.company))
      dispatch(getCurrentCompany())
    } catch (err) {
      dispatch(setErrors(["Invalid username or password"]))
      dispatch(setNotification({ type: "error", message: "Invalid username or password" }))
    }
  }
)

export const signUp = createAsyncThunk<void, SignUpDto, any>(
  "auth/signUp",
  async (args, { dispatch }) => {
    try {
      const response = await api.signUp(args)
      dispatch(setAuthenticated(true))
      dispatch(setToken(response.token))
      dispatch(setUser(response.user))
      dispatch(setCompany(response.company))
      dispatch(getCurrentCompany())
    } catch (err) {
      const payload = (err as AxiosError).response?.data?.error.data
      const errorMessages = Object.keys(payload).map((key) => key + ": " + payload[key])
      dispatch(setErrors(errorMessages))
      dispatch(
        setNotification({
          type: "error",
          message: errorMessages[0] ? errorMessages[0] : "Something went wrong",
        })
      )
    }
  }
)

export const updateAccount = createAsyncThunk<void, UpdateObjectDto<User>, any>(
  "auth/updateAccount",
  async (args, { dispatch }) => {
    try {
      await api.updateAccount(args.id, args.props)
      dispatch(setUser(args.props))
      dispatch(setUpdatedAt(getCurrentDate()))
    } catch (err) {
      const payload = (err as AxiosError).response?.data?.error.data
      const errorMessages = Object.keys(payload).map((key) => key + ": " + payload[key])
      dispatch(setErrors(errorMessages))
      dispatch(
        setNotification({
          type: "error",
          message: errorMessages[0] ? errorMessages[0] : "Something went wrong",
        })
      )
    }
  }
)

export const updatePassword = createAsyncThunk<void, UpdateObjectDto<UpdatePasswordDto>, any>(
  "auth/updatePassword",
  async (args, { dispatch }) => {
    try {
      dispatch(setPasswordChangeStatus(PasswordChangeStatus.LOADING))
      await api.updatePassword(args.id, args.props)
      dispatch(setNotification({ type: "success", message: "Password changed successfully" }))
      dispatch(setPasswordChangeStatus(PasswordChangeStatus.SUCCESS))
    } catch (err) {
      if ((err as AxiosError).response?.status === 401) {
        const errorMessages = ["Current password is incorrect"]
        dispatch(setErrors(errorMessages))
        dispatch(
          setNotification({
            type: "error",
            message: errorMessages[0] ? errorMessages[0] : "Something went wrong",
          })
        )
      } else {
        // console.log("gggg")
        const payload = (err as AxiosError).response?.data?.error.data
        const errorMessages = Object.keys(payload).map((key) => key + ": " + payload[key])
        dispatch(setErrors(errorMessages))
        dispatch(
          setNotification({
            type: "error",
            message: errorMessages[0] ? errorMessages[0] : "Something went wrong",
          })
        )
      }
    }
  }
)

export const resetPassword = createAsyncThunk<
  void,
  ResetPasswordDto,
  { rejectValue: Record<string, string[]> }
>("auth/resetPassword", async (args, { dispatch }) => {
  try {
    await api.resetPassword(args)
    dispatch(setPasswordChangeStatus(PasswordChangeStatus.SUCCESS))
    dispatch(setNotification({ type: "success", message: "Password changed successfully" }))
  } catch (err: any) {
    const payload = (err as AxiosError).response?.data?.error.data
    const errorMessages = Object.keys(payload)
    if (errorMessages.includes("password_confirmation")) {
      dispatch(setErrors(["Password does not match"]))
      dispatch(
        setNotification({
          type: "error",
          message: "Password does not match",
        })
      )
    }
  }
})

export const verifyResetToken = createAsyncThunk<
  void,
  { token: string },
  { rejectValue: Record<string, string[]> }
>("auth/verifyResetToken", async (args, { rejectWithValue, dispatch }) => {
  try {
    await api.verifyResetToken(args)
    dispatch(setPasswordChangeStatus(PasswordChangeStatus.LOADED))
  } catch (err: any) {
    dispatch(setPasswordChangeStatus(PasswordChangeStatus.INVALID))
    return rejectWithValue((err as AxiosError).response?.data?.error.data || null)
  }
})

export const signOut = createAsyncThunk<void, never, { rejectValue: Record<string, string[]> }>(
  "auth/signOut",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await api.signOut()
      dispatch(removeAuth())
      dispatch(unsetCompany())
    } catch (err: any) {
      return rejectWithValue((err as AxiosError).response?.data?.error.data || null)
    }
  }
)
