import { Theme } from "theme-ui"

const theme: Theme = {
  colors: {
    text: "#000000",
    background: "#ffffff",
    primary: "#2D2D94",
    secondary: "#111199",
    muted: "#f6f6f6",
    highlight: "#efeffe", // '#ffffcc',
    gray: "#777777",
    accent: "#660099",
    darken: "rgba(0, 0, 0, .25)",
    border: "rgba(0,0,0,0.3)",
    hoverBackground: "rgba(0,0,0,0.085)",

    modes: {
      dark: {
        text: "hsl(210, 50%, 96%)",
        background: "hsl(230, 25%, 18%)",
        primary: "hsl(260, 100%, 80%)",
        secondary: "hsl(290, 100%, 80%)",
        highlight: "hsl(260, 20%, 40%)",
        accent: "hsl(290, 100%, 80%)",
        muted: "hsla(230, 20%, 0%, 20%)",
        gray: "hsl(210, 50%, 60%)",
        border: "rgba(255,255,255,0.3)",
        hoverBackground: "rgba(255,255,255,0.085)",
      },
    },
  },
  fonts: {
    body: '"Work Sans",system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  // breakpoints: ['40em', '52em', '64em'],
  fontWeights: {
    body: 400,
    heading: 800,
    bold: 700,
    display: 800,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  sizes: {
    sidebar: 256,
    container: 1024,
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
    },
    block: {
      variant: "default",
      my: 2,
      textAlign: "justify",
      textJustify: "auto",
    },
    display: {
      variant: "text.heading",
      fontSize: [5, 6],
      fontWeight: "display",
      letterSpacing: "-0.03em",
      mt: 3,
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.2em",
    },
  },
  buttons: {
    primary: {
      color: "background",
      bg: "primary",
      fontWeight: "bold",
      "&:hover, &:focus": {
        bg: "secondary",
        cursor: "pointer",
      },
    },
    secondary: {
      variant: "buttons.primary",
      color: "background",
      bg: "secondary",
    },
    black: {
      fontWeight: "bold",
      color: "background",
      bg: "text",
      "&:hover, &:focus": {
        bg: "primary",
      },
    },
  },
  links: {
    nav: {
      display: "flex",
      textDecoration: "none",
      alignItems: "center",
      color: "text",
      fontWeight: "bold",
      height: "4rem",
      fontSize: "1.05rem",
      "&.active": {
        boxShadow: (t) => `inset 0rem -0.25rem ${t.colors!.primary}`,
      },
    },
    button: {
      textAlign: "center",
      textDecoration: "none",
      fontWeight: "bold",
      fontSize: 2,
      p: 3,
      color: "primary",
    },
  },
  badges: {
    primary: {
      color: "background",
    },
    highlight: {
      color: "text",
      bg: "highlight",
    },
    accent: {
      color: "background",
      bg: "accent",
    },
    outline: {
      color: "primary",
      bg: "transparent",
      boxShadow: "inset 0 0 0 1px",
    },
    circle: {
      height: 16,
      minWidth: 16,
      lineHeight: "16px",
      textAlign: "center",
      borderRadius: 9999,
    },
  },
  images: {
    avatar: {
      width: 48,
      height: 48,
      borderRadius: 9999,
    },
  },
  cards: {
    primary: {
      // padding: 2,
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      // boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      // border: "2px solid",
      // borderColor: "hoverBackground",
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: "1px solid",
      borderColor: "muted",
    },
  },
  forms: {
    label: {
      fontSize: 2,
      fontWeight: "normal",
    },
    input: {
      borderColor: (t) => `${t.colors!.border}`,
      height: 48,
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors!.primary}`,
        outline: "none",
      },
      "&:disabled": {
        bg: (t) => `${t.colors!.gray}`,
      },
    },
    select: {
      borderColor: "gray",
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors!.primary}`,
        outline: "none",
      },
    },
    textarea: {
      borderColor: "gray",
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors!.primary}`,
        outline: "none",
      },
    },
    slider: {
      bg: "muted",
    },
  },
  alerts: {
    primary: {
      color: "background",
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
    accent: {
      color: "background",
      bg: "accent",
    },
    highlight: {
      color: "text",
      bg: "highlight",
    },
  },
  layout: {
    container: {
      p: 3,
      // maxWidth: 1024,
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    img: {
      maxWidth: "100%",
      height: "auto",
    },
    h1: {
      variant: "text.display",
    },
    h2: {
      variant: "text.heading",
      fontSize: 5,
    },
    h3: {
      variant: "text.heading",
      fontSize: 4,
    },
    h4: {
      variant: "text.heading",
      fontSize: 3,
    },
    h5: {
      variant: "text.heading",
      fontSize: 2,
    },
    h6: {
      variant: "text.heading",
      fontSize: 1,
    },
    a: {
      color: "primary",
      "&:hover": {
        color: "secondary",
      },
    },
    pre: {
      fontFamily: "monospace",
      fontSize: 1,
      p: 3,
      color: "text",
      bg: "muted",
      overflow: "auto",
      code: {
        color: "inherit",
      },
      variant: "prism",
    },
    code: {
      fontFamily: "monospace",
      fontSize: 1,
    },
    inlineCode: {
      fontFamily: "monospace",
      color: "secondary",
      bg: "muted",
    },
    hr: {
      border: 0,
      borderBottom: "1px solid",
      borderColor: "muted",
    },
    xray: {
      "*": {
        outline: "1px solid rgba(0, 192, 255, .25)",
      },
    },
  },
}

export default theme
