import { parseCategories, parseTemplates } from "@/utils/parsers"
import {
  Category,
  Company,
  RequestPasswordDto,
  ResetPasswordDto,
  SignInDto,
  AuthResponse,
  SignUpDto,
  Template,
  User,
  VerifyResetTokenDto,
  UpdatePasswordDto,
} from "@common/interfaces"
import axios, { AxiosInstance } from "axios"

class ApiService {
  base: AxiosInstance
  constructor() {
    this.base = axios.create({
      baseURL: "/api/v1",
    })
  }

  getCategories(): Promise<Category[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { data: allCategories },
        } = await this.base.get("/giftlift/categories?size=1000")
        const { data: enabledCategories } = await this.base.get("/giftlift/categories/enabled")
        const categories = parseCategories(allCategories, enabledCategories)
        resolve(categories)
      } catch (err) {
        reject(err)
      }
    })
  }
  getTemplates(): Promise<Template[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { data: allTemplates },
        } = await this.base.get("/giftlift/templates?size=1000")
        const { data: enabledTemplates } = await this.base.get("/giftlift/templates/enabled")
        const templates = parseTemplates(allTemplates, enabledTemplates)
        resolve(templates)
      } catch (err) {
        reject(err)
      }
    })
  }

  enableCategoryById(id: number) {
    return new Promise((resolve, reject) => {
      this.base
        .put(`/giftlift/categories/${id}/enabled`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }

  disableCategoryById(id: number) {
    return new Promise((resolve, reject) => {
      this.base
        .delete(`/giftlift/categories/${id}/enabled`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }

  enableTemplateById(id: number) {
    return new Promise((resolve, reject) => {
      this.base
        .put(`/giftlift/templates/${id}/enabled`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }

  disableTemplateById(id: number) {
    return new Promise((resolve, reject) => {
      this.base
        .delete(`/giftlift/templates/${id}/enabled`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }

  getCurrentCompany(): Promise<{ company: Company; enabledCompanyId: number; glWidgetID: string }> {
    return new Promise((resolve, reject) => {
      this.base
        .get("/giftlift/companies/current")
        .then(({ data }) => {
          const response = {
            company: {
              id: data.company.id,
              enabledCompanyId: data.id,
              name: data.company.name,
              origins: data.origins,
            },
            enabledCompanyId: data.id,
            glWidgetID: data.gl_widget_id,
          }

          resolve(response)
        })
        .catch((err) => reject(err))
    })
  }

  updateAccount(id: number, props: Partial<User>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.base
        .put(`/accounts/${id}`, props)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }

  updateCompany(id: number, props: Partial<Company>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.base
        .put(`/companies/${id}`, { data: props })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }

  updateCompanyOrigins(id: number, props: Partial<Company>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.base
        .put(`/giftlift/companies/${id}`, props)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }

  getCurrentAccount(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.base
        .get("/accounts/me")
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }

  signIn(props: SignInDto): Promise<AuthResponse> {
    return new Promise((resolve, reject) => {
      this.base
        .post("/giftlift/sessions", props)
        .then(({ data }) => {
          const auth: AuthResponse = {
            token: data.token,
            user: {
              id: data.user.id,
              email: data.user.email,
              firstname: data.user.firstname,
              lastname: data.user.lastname,
              password: "",
              password_confirmation: "",
            },
            company: {
              id: data.user.company_id,
            },
          }
          resolve(auth)
        })
        .catch((err) => reject(err))
    })
  }

  signUp(props: SignUpDto): Promise<AuthResponse> {
    return new Promise((resolve, reject) => {
      this.base
        .post("/giftlift/registrations", props)
        .then(({ data }) => {
          const auth: AuthResponse = {
            token: data.token,
            user: {
              id: data.user.id,
              email: data.user.email,
              firstname: data.user.firstname,
              lastname: data.user.lastname,
              password: "",
              password_confirmation: "",
            },
            company: {
              id: data.user.company_id,
            },
          }
          resolve(auth)
        })
        .catch((err) => reject(err))
    })
  }

  signOut() {
    return new Promise((resolve, reject) => {
      this.base
        .delete("/giftlift/sessions")
        .then(() => resolve(true))
        .catch((err) => reject(err))
    })
  }

  verifyResetToken(props: VerifyResetTokenDto): Promise<any> {
    return new Promise((resolve, reject) => {
      this.base
        .post("/accounts/resetpw/verify", props)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }

  resetPassword(props: ResetPasswordDto): Promise<any> {
    return new Promise((resolve, reject) => {
      this.base
        .post("/accounts/resetpw/change", props)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }

  updatePassword(id: number, props: Partial<UpdatePasswordDto>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.base
        .put(`/accounts/${id}/password`, props)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }

  requestPassword(props: RequestPasswordDto): Promise<any> {
    return new Promise((resolve, reject) => {
      this.base
        .post("/giftlift/accounts/resetpw", props)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }
}

export default new ApiService()
