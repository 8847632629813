import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime)

export function getRelativeTime(relativeDate: number) {
  return dayjs.unix(relativeDate).fromNow()
}

export function getCurrentDate() {
  return dayjs().unix()
}
