import { useState } from "react"
import { Input, Button, Label, Heading, Box, Text } from "theme-ui"
import { useSelector } from "react-redux"
import { selectPasswordChangeStatus, selectUser } from "@store/slices/auth/selectors"
import { useAppDispatch } from "@/store/store"
import { updateAccount, updatePassword } from "@/store/slices/auth/actions"
import * as yup from "yup"
import { useForm, SubmitHandler } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ErrorMessage } from "@hookform/error-message"
import { UpdatePasswordDto } from "@/common/interfaces"
import { useEffect } from "react"
import { PasswordChangeStatus } from "@/common/constants"

const changePasswordSchema = yup.object().shape({
  current_password: yup.string().required("Required field"),
  password: yup
    .string()
    .required("Required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@?#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "New passwords must match"),
})

const resetPasswordDefaults = { current_password: "", password: "", password_confirmation: "" }

function UserDetails() {
  const dispatch = useAppDispatch()
  const user = useSelector(selectUser)
  const passwordChangeStatus = useSelector(selectPasswordChangeStatus)
  const [email, setEmail] = useState(user?.email)

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm<UpdatePasswordDto>({
    resolver: yupResolver(changePasswordSchema),
    defaultValues: resetPasswordDefaults,
  })

  const handleEmailUpdate = () => {
    dispatch(
      updateAccount({
        id: user?.id!,
        props: {
          email: email,
        },
      })
    )
  }

  const onSubmit: SubmitHandler<UpdatePasswordDto> = async (data: UpdatePasswordDto) => {
    dispatch(
      updatePassword({
        id: user?.id!,
        props: data,
      })
    )
  }

  useEffect(() => {
    if (passwordChangeStatus === PasswordChangeStatus.SUCCESS) {
      clearErrors()
      reset(resetPasswordDefaults)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordChangeStatus])

  return (
    <Box sx={{ flex: 1, padding: ["0 1rem 1rem", "0 2rem 1rem", "3rem 3rem 1rem"] }}>
      <Box as="form">
        <Heading mb={2} mt={1} as="h3">
          User Details
        </Heading>

        <Label htmlFor="email">Email</Label>
        <Input
          name="email"
          id="email"
          mb={3}
          mt={2}
          value={email}
          onBlur={handleEmailUpdate}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Box>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Heading mb={2} mt={1} as="h4">
          Reset Password
        </Heading>

        <Box mb={3}>
          <Label htmlFor="currentPassword">Current Password</Label>
          <Input
            {...register("current_password")}
            type="password"
            mb={1}
            mt={2}
            onChange={() => clearErrors()}
          />
          <Text color="#e74c3c">
            <ErrorMessage errors={errors} name="current_password" />
          </Text>
        </Box>

        <Box mb={3}>
          <Label>New Password</Label>
          <Input
            {...register("password")}
            type="password"
            mb={1}
            mt={2}
            onChange={() => clearErrors()}
          />
          <Text color="#e74c3c">
            <ErrorMessage errors={errors} name="password" />
          </Text>
        </Box>

        <Box mb={3}>
          <Label>Confirm New Password</Label>
          <Input
            {...register("password_confirmation")}
            mb={1}
            mt={2}
            type="password"
            onChange={() => clearErrors()}
          />
          <Text color="#e74c3c">
            <ErrorMessage errors={errors} name="password_confirmation" />
          </Text>
        </Box>

        <Button sx={{ height: "54px", width: "100%" }} mt={2}>
          Update Password
        </Button>
      </Box>
    </Box>
  )
}
export default UserDetails
