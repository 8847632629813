import { FormEvent, useState } from "react"
import { Flex, Box } from "theme-ui"
import { RequestPasswordDto } from "@common/interfaces"
import api from "@services/api"
import ResetPasswordForm from "./ResetPasswordForm"
import CheckInbox from "./CheckInbox"

function ResetPassword() {
  const [params, setParams] = useState<RequestPasswordDto>({
    email: "",
  })
  const [isSent, setIsSent] = useState(false)

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    await api.requestPassword(params)
    setIsSent(true)
  }

  return (
    <Flex sx={{ height: "100vh" }}>
      <Box sx={{ display: ["none", "none", "flex"], flex: 1.2 }}>
        <Flex
          sx={{
            bg: "primary",
            flex: 1.2,
          }}
        ></Flex>
      </Box>
      {isSent ? (
        <CheckInbox email={params.email} />
      ) : (
        <ResetPasswordForm params={params} setParams={setParams} onSubmit={handleSubmit} />
      )}
    </Flex>
  )
}

export default ResetPassword
