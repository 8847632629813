import { Flex, Box, Image, Text, Heading } from "theme-ui"
import Link from "@components/Link/Link"
import Logo from "@assets/logo.png"

interface CheckInboxProps {
  email: string
}
function CheckInbox({ email }: CheckInboxProps) {
  return (
    <Flex
      style={{
        justifyContent: "center",
        flex: 1,
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Image mt={4} width="140px" src={Logo} />
      </Box>
      <Flex
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Flex
          sx={{
            maxWidth: "520px",
            width: "100%",
            padding: "2rem",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Heading as="h1">Check Your Inbox</Heading>
          <Text my={3}>
            We’ve sent a recovery email to <b>{email}</b>
          </Text>

          <Link variant="button" to="/sign-in">
            Back to Log In
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default CheckInbox
