import { combineReducers } from "@reduxjs/toolkit"
import { authReducer } from "@store/slices/auth/reducer"
import persistReducer from "redux-persist/es/persistReducer"
import { PersistConfig } from "redux-persist/es/types"
import storage from "redux-persist/lib/storage"
import { AuthState } from "./slices/auth/reducer"
import { occasionsReducer } from "./slices/occasions/reducer"
import { detailsReducer, DetailsState } from "./slices/details/reducer"
import { feedbackReducer } from "./slices/feedback/reducer"

const authPersistConfig: PersistConfig<AuthState> = {
  key: "auth",
  storage,
  blacklist: ["errors", "passwordChageStatus"],
}

const detailsPersistConfig: PersistConfig<DetailsState> = {
  key: "details",
  storage,
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  occassions: occasionsReducer,
  details: persistReducer(detailsPersistConfig, detailsReducer),
  feedback: feedbackReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
