export const htmlString = '<div id="giftlift-widget"></div>'

export const scriptString = (glWidgetID: string, price: string) => {
  if (price) {
    return `<script> 
    (function (w,d,s,o,f,js,fjs) { 
      w['JS-Widget']=o;
      w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; 
      js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; 
      js.id = o; js.src = f; js.async = 1; 
      fjs.parentNode.insertBefore(js, fjs); 
    }(window, document, 'script', 'mw',  'https://dzqi3b5s5jl7u.cloudfront.net/giftlift/widget/app.bundle.js')); 
    mw('init', { gl_widget_id: '${glWidgetID}', container: 'giftlift-widget' }); 
    mw('setPrice', ${price});
</script>`
  } else {
    return `<script> 
    (function (w,d,s,o,f,js,fjs) { 
      w['JS-Widget']=o;
      w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; 
      js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; 
      js.id = o; js.src = f; js.async = 1; 
      fjs.parentNode.insertBefore(js, fjs); 
    }(window, document, 'script', 'mw',  'https://dzqi3b5s5jl7u.cloudfront.net/giftlift/widget/app.bundle.js')); 
    mw('init', { gl_widget_id: '${glWidgetID}', container: 'giftlift-widget' }); 
</script>`
  }
}

export enum occasionsOption {}

export enum SettingsOption {
  MAIN = "main",
  DETAIL = "details",
  INSTALLATION = "installation",
}

export enum PasswordChangeStatus {
  LOADING = "loading",
  INVALID = "invalid",
  LOADED = "loaded",
  SUCCESS = "success",
}
