import { Flex, Image, Text, Box } from "theme-ui"
import { useHistory } from "react-router-dom"
import Link from "@components/Link/Link"
import SignOutIcon from "@components/Icons/Signout"
import { useAppDispatch } from "@store/store"
import { signOut } from "@store/slices/auth/actions"
import Logo from "@assets/logo.png"

function NavbarClean() {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const signOutHandler = async () => {
    await dispatch(signOut())
    history.push("/sign-in")
  }

  return (
    <Flex
      sx={{
        flexDirection: "column",
        px: ["1rem", "2rem", "3rem"],
      }}
    >
      <Flex sx={{ justifyContent: "space-between" }}>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            height: "4rem",
          }}
        >
          <Link to="/">
            <Image sx={{ display: "block", width: "104px" }} src={Logo} />
          </Link>
        </Flex>

        <Flex
          sx={{
            color: "primary",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Flex sx={{ cursor: "pointer" }} onClick={() => signOutHandler()}>
            <SignOutIcon size={24} />
            <Text ml={2} sx={{ color: "text", fontWeight: "bold" }}>
              Log Out
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Box
        sx={{
          display: ["block", "block", "block", "none"],
        }}
      >
        {/* {!contextMenu && <NavLinks />} */}
      </Box>
    </Flex>
  )
}

export default NavbarClean
