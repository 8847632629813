import SyntaxHighlighter from "react-syntax-highlighter"
import darkHighlight from "react-syntax-highlighter/dist/esm/styles/hljs/shades-of-purple"
import lightHighlight from "react-syntax-highlighter/dist/esm/styles/hljs/atelier-sulphurpool-light"
import { useThemeUI } from "theme-ui"

function CodeBlock({ text }: { text: string }) {
  const theme = useThemeUI()
  return (
    <SyntaxHighlighter
      style={
        theme.colorMode === "default" || theme.colorMode === "light"
          ? lightHighlight
          : {
              ...darkHighlight,
              hljs: {
                ...darkHighlight.hljs,
                background: "rgba(0,0,0,0.2)",
              },
            }
      }
    >
      {text}
    </SyntaxHighlighter>
  )
}

export default CodeBlock
