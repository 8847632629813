import { Category, Template } from "@common/interfaces"

export function parseCategories(allCategories: any[], enabledCategories: any[]) {
  const categories = allCategories.map((ac) => {
    const isEnabled = enabledCategories.find((ec) => ec.id === ac.id)
    const category: Category = {
      enabled: !!isEnabled,
      id: ac.id,
      name: ac.name,
    }
    return category
  })

  return categories
}

export function parseTemplates(allTemplates: any[], enabledTemplates: any[]): Template[] {
  const templates = allTemplates.map((at) => {
    const isEnabled = enabledTemplates.find((et) => et.id === at.id)
    const template = {
      enabled: !!isEnabled,
      id: at.id,
      name: at.name,
      preview: at.image_url,
      categoryId: at.category_id,
    }
    return template
  })
  return templates
}
