import { Input, Button, Flex, Label, Heading, Box, Image, Text } from "theme-ui"
import Link from "@components/Link/Link"
import { useAppDispatch } from "@store/store"
import { signIn } from "@store/slices/auth/actions"
import { SignInDto } from "@common/interfaces"
import { useForm, SubmitHandler } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Logo from "@assets/logo.png"

const signInSchema = yup.object().shape({
  user: yup.object({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup.string().required("Password is required"),
  }),
})

function SignInForm() {
  const dispath = useAppDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInDto>({
    resolver: yupResolver(signInSchema),
  })

  const onSubmit: SubmitHandler<SignInDto> = (data) => {
    dispath(signIn(data))
  }

  return (
    <Flex
      style={{
        justifyContent: "center",
        flex: 1,
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Image mt={4} width="140px" src={Logo} />
      </Box>
      <Flex
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Flex
          as="form"
          sx={{
            maxWidth: "420px",
            width: "100%",
            padding: "2rem",
            flexDirection: "column",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Heading as="h2" mb={4} sx={{ textAlign: "center" }}>
            Sign In
          </Heading>
          <Box mb={3}>
            <Label>Email</Label>
            <Input {...register("user.email")} mt={2} mb={2} />
            <Text color="#e74c3c">{errors.user?.email?.message}</Text>
          </Box>
          <Box mb={3}>
            <Label>Password</Label>
            <Input type="password" {...register("user.password")} mt={2} mb={2} />
            <Text color="#e74c3c">{errors.user?.password?.message}</Text>
          </Box>

          <Button sx={{ height: "54px" }} mt={2}>
            Sign In
          </Button>
          <Link variant="button" to="/reset-password">
            Forgot password
          </Link>
        </Flex>
      </Flex>
      <Box m={4} sx={{ textAlign: "center" }}>
        <Text>
          Don’t have an account?
          <br />
        </Text>
        <Link variant="button" to="/sign-up">
          Sign Up
        </Link>
      </Box>
    </Flex>
  )
}

export default SignInForm
