import { createAsyncThunk, createAction, Update } from "@reduxjs/toolkit"
import { getCurrentDate } from "@/utils/date"
import { Category, Template } from "@common/interfaces"
import api from "@services/api"
import { AxiosError } from "axios"

export const setCategories = createAction<Category[]>("occasions/setCategories")
export const setTemplates = createAction<Template[]>("occasions/setTemplates")

export const updateOneCategory = createAction<Update<Category>>("occasions/updateOneCategory")
export const updateOneTemplate = createAction<Update<Template>>("occasions/updateOneTemplate")
export const setCategorySelectedId = createAction<number>("occasions/setCategorySelectedId")
export const setUpdatedAt = createAction<number>("occasions/setUpdatedAt")

export const getCategories = createAsyncThunk<
  void,
  never,
  { rejectValue: Record<string, string[]> }
>("auth/getCategories", async (_, { rejectWithValue, dispatch }) => {
  try {
    const categories = await api.getCategories()
    dispatch(setCategories(categories))
    if (categories[0]) {
      dispatch(setCategorySelectedId(categories[0].id))
    }
  } catch (err) {
    return rejectWithValue((err as AxiosError).response?.data?.error.data || null)
  }
})

export const getTemplates = createAsyncThunk<
  void,
  never,
  { rejectValue: Record<string, string[]> }
>("auth/getTemplates", async (_, { rejectWithValue, dispatch }) => {
  try {
    const templates = await api.getTemplates()
    dispatch(setTemplates(templates))
  } catch (err) {
    return rejectWithValue((err as AxiosError).response?.data?.error.data || null)
  }
})

export const updateCategoryById = createAsyncThunk<void, Category, { rejectValue: string }>(
  "auth/updateCategoryById",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      if (args.enabled) {
        api.enableCategoryById(args.id!)
      } else {
        api.disableCategoryById(args.id!)
      }
      dispatch(
        updateOneCategory({
          id: args.id,
          changes: {
            enabled: args.enabled,
          },
        })
      )
      dispatch(setUpdatedAt(getCurrentDate()))
    } catch (err) {
      return rejectWithValue((err as AxiosError).response?.data?.error || null)
    }
  }
)

export const updateTemplateById = createAsyncThunk<void, Category, { rejectValue: string }>(
  "auth/updateTemplateById",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      if (args.enabled) {
        api.enableTemplateById(args.id!)
      } else {
        api.disableTemplateById(args.id!)
      }
      dispatch(
        updateOneTemplate({
          id: args.id,
          changes: {
            enabled: args.enabled,
          },
        })
      )
      dispatch(setUpdatedAt(getCurrentDate()))
    } catch (err) {
      return rejectWithValue((err as AxiosError).response?.data?.error || null)
    }
  }
)
