import { selectUpdatedAt } from "@store/slices/occasions/selectors"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Box, Flex, Text } from "theme-ui"
import isNull from "lodash/isNull"
import { getRelativeTime } from "@/utils/date"

function Description() {
  const updatedAt = useSelector(selectUpdatedAt)
  const [fromNow, setFromNow] = useState("Now")
  useEffect(() => {
    const updateFromNow = (updatedAt: number) => {
      const timeVar = getRelativeTime(updatedAt)
      setFromNow(timeVar)
    }
    let interval: any
    if (isNull(updatedAt)) {
      setFromNow("Now")
    } else {
      updateFromNow(updatedAt)
      interval = setInterval(() => updateFromNow(updatedAt), 5000)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [updatedAt])

  return (
    <Box py="1rem" px={["1rem", "2rem", "3rem"]}>
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: ["space-between", "flex-start"],
          paddingBottom: "1rem",
        }}
      >
        <Text as="h2" sx={{ paddingRight: "1.5rem" }}>
          Edit design for occasions
        </Text>
        <Text>Last saved: {fromNow}</Text>
      </Flex>
      <Box sx={{ maxWidth: "840px" }}>
        <Text>
          Only gift card Occasions and Templates that are toggled on will be visible to your
          customers within the GiftLift widget on your website.
        </Text>
      </Box>
    </Box>
  )
}

export default Description
