import { Flex } from "theme-ui"
import Navbar from "@components/Navbar"
import Scripts from "./Guide"

function Installation() {
  return (
    <Flex
      sx={{
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <Scripts />
    </Flex>
  )
}

export default Installation
