import { Flex, Text } from "theme-ui"
import AngleLeft from "../Icons/AngleLeft"

interface Props {
  text: string
  onClick: () => void
}

function ContextMenu({ text, onClick }: Props) {
  return (
    <Flex
      sx={{
        px: ["1rem", "2rem", "3rem"],
        height: 50,
        position: "relative",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <Text onClick={onClick} sx={{ color: "primary", position: "absolute", cursor: "pointer" }}>
        <AngleLeft size={20} />
      </Text>
      <Text as="h3" sx={{ flex: 1 }}>
        {text}
      </Text>
    </Flex>
  )
}

export default ContextMenu
