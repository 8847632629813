import React, { useEffect, useState } from "react"
import { Input, Flex, Box } from "theme-ui"

const defaultSeprators = ["Enter", ",", "Tab"]

interface InputTagProps {
  value: string[]
  onChange: (values: string[]) => void
  onUpdated: (values: string[]) => void
}
function InputTag({ value, onChange, onUpdated }: InputTagProps) {
  const [values, setValues] = useState<string[]>(value)
  const [errors, setErrors] = useState(false)
  // @ts-ignore
  const regex = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i
  )

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setErrors(false)

    e.stopPropagation()
    // @ts-ignore
    const text = e.target.value
    if (text && defaultSeprators.includes(e.key)) {
      if ((text as string).match(regex)) {
        setValues([...values, text])
        // @ts-ignore
        e.target.value = ""
        e.preventDefault()
        onUpdated([...values, text])
        setErrors(false)
      } else {
        setErrors(true)
      }
    }
  }
  const handleRemove = (text: string) => {
    const updatedValues = values.filter((value) => value !== text)
    setValues(updatedValues)
    onUpdated(updatedValues)
  }

  useEffect(() => {
    onChange && onChange(values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return (
    <Box mb={3}>
      <Input
        sx={{ color: errors ? "red" : "#000000" }}
        onKeyDown={handleOnKeyUp}
        id="websites"
        mb={2}
        mt={2}
        placeholder="https://foo.bar"
      />
      {values.map((value) => (
        <Flex key={value}>
          <Flex
            sx={{
              marginTop: "0.3rem",
              alignItems: "center",
              bg: "hoverBackground",
              borderRadius: "0.375rem",
              display: "flex",
              justifyContent: "center",
              paddingLeft: "0.5rem",
              cursor: "pointer",
              button: {
                bg: "hoverBackground",
                border: 0,
                borderRadius: "50%",
                cursor: "pointer",
                lineHeight: "inherit",
                padding: "0 0.5rem",
                "&:hover": {
                  color: "#e53e3e",
                },
              },
            }}
          >
            <Flex>{value}</Flex>
            <Box sx={{ padding: "0.3rem" }} onClick={() => handleRemove(value)}>
              &#10005;
            </Box>
          </Flex>
        </Flex>
      ))}
    </Box>
  )
}

export default InputTag
