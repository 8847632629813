import { PasswordChangeStatus } from "@common/constants"
import { User } from "@common/interfaces"
import { createReducer, current } from "@reduxjs/toolkit"
import { removeAuth, setAuthenticated, setPasswordChangeStatus, setToken, setUser } from "./actions"

export interface AuthState {
  isAuthenticated: boolean
  token: string | null
  user: Partial<User> | null
  errors: string[]
  passwordChageStatus: PasswordChangeStatus
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  token: null,
  errors: [],
  passwordChageStatus: PasswordChangeStatus.LOADING,
}

export const authReducer = createReducer(initialState, (builder) => {
  builder.addCase(setAuthenticated, (state, { payload }) => {
    state.isAuthenticated = payload
  })

  builder.addCase(removeAuth, (state) => {
    state.isAuthenticated = false
    state.token = null
    state.user = null
  })

  builder.addCase(setToken, (state, { payload }) => {
    state.token = payload
  })

  builder.addCase(setUser, (state, { payload }) => {
    const currentState = current(state)
    state.user = currentState.user ? { ...currentState.user, ...payload } : payload
  })

  builder.addCase(setPasswordChangeStatus, (state, { payload }) => {
    state.passwordChageStatus = payload
  })
})
