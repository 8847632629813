import { FormEvent } from "react"
import { Input, Button, Flex, Label, Box, Image, Text } from "theme-ui"
import { RequestPasswordDto } from "@common/interfaces"
import Link from "@components/Link/Link"
import Logo from "@assets/logo.png"

interface ResetPasswordFormProps {
  params: RequestPasswordDto
  setParams: React.Dispatch<React.SetStateAction<RequestPasswordDto>>
  onSubmit: (e: FormEvent) => Promise<void>
}
function ResetPasswordForm({ params, setParams, onSubmit }: ResetPasswordFormProps) {
  return (
    <Flex
      style={{
        justifyContent: "center",
        flex: 1,
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Image mt={4} width="140px" src={Logo} />
      </Box>
      <Flex
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Flex
          as="form"
          sx={{
            maxWidth: "420px",
            width: "100%",
            padding: "2rem",
            flexDirection: "column",
          }}
          onSubmit={onSubmit}
        >
          <Text as="h2" mb={4} sx={{ textAlign: "center" }}>
            Forgot Password
          </Text>

          <Label htmlFor="email">Email</Label>
          <Input
            onChange={(e) => setParams({ ...params, email: e.target.value })}
            name="email"
            id="email"
            mb={3}
            mt={2}
          />

          <Button sx={{ height: "54px" }} mt={2}>
            Send Reset Email
          </Button>
          <Link variant="button" to="/sign-in">
            Cancel
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ResetPasswordForm
