import { Flex } from "theme-ui"
import useAppContext from "@hooks/useAppContext"
import { selectSelectedCategory } from "@store/slices/occasions/selectors"
import { useSelector } from "react-redux"
import ContextMenu from "@components/ContextMenu/ContextMenu"
import Templates from "./Templates"
import Categories from "./Categories"
import Description from "./Description"

function Mobile() {
  const { contextMenu, setContextMenu } = useAppContext()
  const selectedCategory = useSelector(selectSelectedCategory)
  return (
    <Flex sx={{ flex: 1, flexDirection: "column" }}>
      {contextMenu === null ? (
        <>
          <Description />
          <Categories />
        </>
      ) : (
        <>
          <ContextMenu
            onClick={() => setContextMenu(null)}
            text={selectedCategory ? selectedCategory.name : "Category"}
          />
          <Templates />
        </>
      )}
    </Flex>
  )
}

export default Mobile
