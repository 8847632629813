import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Input, Flex, Label, Heading, Box, Text } from "theme-ui"
import { useAppDispatch } from "@store/store"
import {
  selectCompany,
  selectEnabledCompanyId,
  selectPrice,
  selectUpdatedAt,
} from "@store/slices/details/selectors"
import { setPrice, updateCompany, updateCompanyOrigins } from "@store/slices/details/actions"
import TagsInput from "@components/TagsInput"
import { getRelativeTime } from "@/utils/date"
import isNull from "lodash/isNull"
import Popup from "reactjs-popup"
import InfoIcon from "@components/Icons/Info"

function Details() {
  const dispatch = useAppDispatch()
  const company = useSelector(selectCompany)
  const price = useSelector(selectPrice)
  const [companyName, setCompanyName] = useState(company?.name)
  const [origins, setOrigins] = useState<string[]>(company?.origins || [])

  const [widgetPrice, setWidgetPrice] = useState(price)

  const enabledCompanyId = useSelector(selectEnabledCompanyId)

  const handleNameUpdate = () => {
    dispatch(updateCompany({ id: company?.id!, props: { name: companyName } }))
  }

  const handleOriginUpdate = (values: string[]) => {
    console.log({ values })
    dispatch(
      updateCompanyOrigins({
        id: enabledCompanyId,
        props: { origins: values },
      })
    )
  }

  const updatedAt = useSelector(selectUpdatedAt)
  const [fromNow, setFromNow] = useState("Now")

  const updateWidgetPrice = () => {
    dispatch(setPrice(widgetPrice))
  }
  useEffect(() => {
    const updateFromNow = (updatedAt: number) => {
      const timeVar = getRelativeTime(updatedAt)
      setFromNow(timeVar)
    }
    let interval: any
    if (isNull(updatedAt)) {
      setFromNow("Now")
    } else {
      updateFromNow(updatedAt)
      interval = setInterval(() => updateFromNow(updatedAt), 5000)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [updatedAt])

  return (
    <Flex
      sx={{
        flex: 1,
        flexDirection: "column",
        padding: ["2rem 1rem 1rem", "2rem 2rem 1rem", "3rem 3rem 1rem"],
      }}
    >
      <Box>
        <Box>
          <Flex>
            <Heading mb={2} pr={3} as="h3">
              Company Details
            </Heading>
            <Text>Last saved: {fromNow}</Text>
          </Flex>
          <Label>Name</Label>
          <Input
            name="name"
            id="name"
            mb={3}
            mt={2}
            value={companyName}
            onBlur={handleNameUpdate}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <Box>
            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
              <Label htmlFor="website">Websites</Label>
              <Popup
                on="hover"
                trigger={
                  <Flex sx={{ color: "accent", paddingLeft: "0.5rem" }}>
                    <InfoIcon size={18} />
                  </Flex>
                }
                position="bottom left"
              >
                <Box
                  sx={{
                    // marginLeft: "3rem",
                    bg: "background",
                    cursor: "pointer",
                    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
                    padding: "0.5rem",
                    maxWidth: "540px",
                  }}
                >
                  <Text>
                    Enter the domains of all the sites on which you are embedding the GiftLift
                    widget. Each listed domain must be of the form https://google.com. This
                    information will be used to set the CORS configuration of our API to allow calls
                    from your sites.
                  </Text>
                </Box>
              </Popup>
            </Box>

            <TagsInput onChange={setOrigins} value={origins} onUpdated={handleOriginUpdate} />
          </Box>

          <Label>Gift Card Price (Optional)</Label>
          <Input
            name="price"
            id="price"
            mb={3}
            mt={2}
            placeholder="0.00"
            value={widgetPrice}
            onBlur={updateWidgetPrice}
            onChange={(e) => setWidgetPrice(e.target.value)}
          />
        </Box>
      </Box>
    </Flex>
  )
}

export default Details
