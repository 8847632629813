import { useEffect, useRef } from "react"
import { Box } from "theme-ui"
import ResizeObserver from "resize-observer-polyfill"
import useAppContext from "./hooks/useAppContext"
import NotificationsManager from "./NotificationsManager"

function AppBox({ children }: { children: any }) {
  const containerRef = useRef<HTMLDivElement>(null)
  const { isMobile, setIsMobile } = useAppContext()

  const updateMediaQuery = (value: number) => {
    if (!isMobile && value >= 1024) {
      setIsMobile(false)
    } else if (!isMobile && value < 1024) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  useEffect(() => {
    const containerElement = containerRef.current
    const containerWidth = containerElement!.clientWidth
    updateMediaQuery(containerWidth)
    const resizeObserver = new ResizeObserver((entries) => {
      const { width = containerWidth } = (entries[0] && entries[0].contentRect) || {}
      updateMediaQuery(width)
    })
    resizeObserver.observe(containerElement!)
    return () => {
      if (containerElement) {
        resizeObserver.unobserve(containerElement)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      ref={containerRef}
      sx={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <NotificationsManager />
      {children}
    </Box>
  )
}

export default AppBox
