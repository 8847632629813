import React, { createContext, useState, FC } from "react"

interface IAppContext {
  isMobile: boolean
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>
  contextMenu: number | null
  setContextMenu: React.Dispatch<React.SetStateAction<null | number>>
}

export const AppContext = createContext<IAppContext>({
  isMobile: false,
  setIsMobile: () => {},
  contextMenu: null,
  setContextMenu: () => {},
})

export const AppProvider: FC = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [contextMenu, setContextMenu] = useState<number | null>(null)
  const context = { isMobile, setIsMobile, contextMenu, setContextMenu }
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>
}
