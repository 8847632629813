import { htmlString, scriptString } from "@/common/constants"
import CodeBlock from "@/components/CodeBlock"
import { Box, Flex, Heading, Text } from "theme-ui"
import { Link } from "react-scroll"
import copy from "copy-to-clipboard"
import CopyIcon from "@/components/Icons/Copy"
import { useSelector } from "react-redux"
import { selectglWidgetID, selectPrice } from "@/store/slices/details/selectors"

function SideLink({ children, to }: any) {
  return (
    <Link
      className="sideLink"
      activeClass="activeSideLink"
      spy={true}
      smooth={true}
      duration={300}
      to={to}
    >
      {children}
    </Link>
  )
}

function IntegragrionGuide() {
  const glWidgetID = useSelector(selectglWidgetID)
  const price = useSelector(selectPrice)
  return (
    <Box sx={{ fontFamily: "system-ui" }}>
      <Box sx={{ cursor: "default", px: ["1rem", "2rem", "3rem"] }}>
        <Flex>
          <Box
            sx={{
              display: ["none", "none", "block"],
              width: "280px",
              flex: "none",
              paddingTop: "2rem",
              color: "text",
              paddingRight: "2rem",
            }}
          >
            <Box sx={{ position: "sticky", top: 10, fontSize: "1rem" }}>
              <Box sx={{ position: "relative" }}>
                <Box sx={{ position: "absolute" }}>
                  <Box sx={{ fontWeight: 500, cursor: "pointer" }} py={1}>
                    <SideLink to="section1">How The Widget Works</SideLink>
                  </Box>
                  <Box>
                    <Box sx={{ cursor: "pointer", padding: "0.15rem 0 0.15rem 1rem" }}>
                      <SideLink to="section2">Initialization</SideLink>
                    </Box>
                    <Box sx={{ cursor: "pointer", padding: "0.15rem 0 0.15rem 1rem" }}>
                      <SideLink to="section3"> Gift Message Selection</SideLink>
                    </Box>
                    <Box sx={{ cursor: "pointer", padding: "0.15rem 0 0.15rem 1rem" }}>
                      <SideLink to="section4">Gift Message Storage</SideLink>
                    </Box>
                    <Box sx={{ cursor: "pointer", padding: "0.15rem 0 0.15rem 1rem" }}>
                      <SideLink to="section5">Gift Message Saved Event</SideLink>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ fontWeight: 500 }} py={1}>
                      <SideLink to="section6">Embed the GiftLift Widget</SideLink>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ fontWeight: 500 }} py={1}>
                      <SideLink to="section7">Integrate with the GiftLift Widget</SideLink>
                    </Box>
                    <Box>
                      <Box sx={{ cursor: "pointer", padding: "0.15rem 0 0.15rem 1rem" }}>
                        <SideLink to="section8">Listening for the gl_token_ready Event</SideLink>
                      </Box>
                      <Box sx={{ cursor: "pointer", padding: "0.15rem 0 0.15rem 1rem" }}>
                        <SideLink to="section9">Storing the GiftLift Token</SideLink>
                      </Box>
                      <Box sx={{ cursor: "pointer", padding: "0.15rem 0 0.15rem 1rem" }}>
                        <SideLink to="section19">Submit Order to Fulfilment Endpoint</SideLink>
                      </Box>
                      <Box sx={{ cursor: "pointer", padding: "0.15rem 0 0.15rem 1rem" }}>
                        <SideLink to="section11">Reference Guide</SideLink>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%", paddingTop: "2rem", marginBottom: "30rem" }}>
            <Box sx={{ maxWidth: 800, margin: "auto" }}>
              <Heading sx={{ fontSize: ["1.7rem", "1.8rem", "1.9rem"] }} as="h1">
                GiftLift Integration Guide
              </Heading>
              <Heading id="section1" py={4} as="h2">
                How The Widget Works
              </Heading>
              <Text>
                It is important to understand how the GiftLift widget interacts with the GiftLift
                service and your shopping cart to provide a high quality gift messaging service to
                your customers.
              </Text>
              <Heading id="section2" sx={{ fontWeight: 500, fontSize: "1.1rem" }} py={3} as="h4">
                Initialization
              </Heading>
              <Text>
                When the GiftLift widget is loaded and initialized, it will call the GiftLift API to
                obtain the list of gift message Occasions and Templates that you enabled in the
                GiftLift Web application.
              </Text>
              <Heading id="section3" sx={{ fontWeight: 500, fontSize: "1.1rem" }} py={3} as="h4">
                Gift Message Selection
              </Heading>
              <Text>
                In the embedding page, your customer can then select an Occasion and Template in the
                GiftLift widget, enter their gift message in the text box, and press the Save button
                to store their gift message.
              </Text>
              <Heading id="section4" sx={{ fontWeight: 500, fontSize: "1.1rem" }} py={3} as="h4">
                Gift Message Storage
              </Heading>
              <Text>
                When the consumer presses the Save button, the widget will send a message to the
                GiftLift API to store the gift message information. If successful, the GiftLift API
                will respond to the widget with a unique token that uniquely identifies the
                submitted gift message.
              </Text>
              <Heading id="section5" sx={{ fontWeight: 500, fontSize: "1.1rem" }} py={3} as="h4">
                Gift Message Saved Event
              </Heading>
              <Text>
                Upon receiving the token, the GiftLift widget will dispatch a Javascript event on
                the ‘window’ object with the name 'gl_token_ready' that the embedding application
                can listen for to find out that the customer made a gift message selection. More
                information about listening for this event can be found below. Note: your customer
                can edit the Occasion, Template and Message, and re-save their new selection, as
                many times as they want before they complete their checkout. The GiftLift token,
                once assigned, will stay the same across any edits the user might make. There can be
                at most one gift message associated with any customer order.
              </Text>
              <Heading id="section6" sx={{ fontSize: "1.4rem" }} py={4} as="h2">
                Embed the GiftLift Widget
              </Heading>
              <Text>
                Embedding the GiftLift widget into your shopping cart requires two simple steps:
              </Text>
              <br />
              <ol>
                <li>
                  <Text>
                    Add the following HTML 'div' tag to the embedding page. This tag acts as a
                    container for the GiftLift widget and should be positioned wherever you want the
                    GiftLift widget to be displayed.
                    {/* <CodeBlock text='<div id="giftlift-widget"></div>' /> */}
                    <Box sx={{ position: "relative" }}>
                      <CodeBlock text={htmlString} />
                      <Flex
                        onClick={() => {
                          copy(htmlString)
                        }}
                        sx={{
                          color: "primary",
                          position: "absolute",
                          top: -2,
                          right: -2,
                          cursor: "pointer",
                        }}
                      >
                        <CopyIcon size={18} />
                      </Flex>
                    </Box>
                  </Text>
                </li>
                <li>
                  <Text>
                    Add the following Javascript to the embedding page or application to load and
                    initialize the GiftLift widget.
                  </Text>
                  <br />

                  <Box mt="5px">
                    <Text>
                      <b> Note:</b> if you change the price on the Settings page, this Javascript
                      will be updated so you may need to update the script in your web pages.
                    </Text>
                  </Box>
                  <Box sx={{ position: "relative" }}>
                    <CodeBlock text={scriptString(glWidgetID, price)} />

                    <Flex
                      onClick={() => {
                        copy(scriptString(glWidgetID, price))
                      }}
                      sx={{
                        color: "primary",
                        position: "absolute",
                        top: -2,
                        right: -2,
                        cursor: "pointer",
                      }}
                    >
                      <CopyIcon size={18} />
                    </Flex>
                  </Box>
                </li>
              </ol>

              <Text>Note:</Text>
              <ul>
                <li>
                  <Text>
                    If you are satisfied to have the GiftLift widget load and initialize immediately
                    when your embedding page loads, then the Javascript above can be placed anywhere
                    inside the 'body' tag after the 'div' from step 1 occurs.
                  </Text>
                </li>
                <br />

                <li>
                  <Text>
                    If, however, you want to defer and control when the widget is loaded,
                    initialized and displayed, then you will need to extract the Javascript function
                    from the script tags, add it to the embedding application and call it at the
                    appropriate time.
                  </Text>
                  <Box mt={"5px"}></Box>
                  <Text>
                    For example, you might choose to add a button to your shopping cart page that,
                    when clicked, loads and displays the GiftLift widget and also adds an item to
                    the shopping cart to charge for the gift message.
                  </Text>
                </li>
              </ul>

              <Heading id="section7" sx={{ fontSize: "1.4rem" }} py={4} as="h2">
                Integrate with the GiftLift Widget
              </Heading>
              <Text>
                Once the GiftLift widget is successfully embedded in your web page, the next step is
                to integrate the widget with the embedding application.
              </Text>
              <Heading id="section8" sx={{ fontWeight: 500, fontSize: "1.1rem" }} py={3} as="h4">
                Listening for the gl_token_ready Event
              </Heading>
              <Text>
                Although it is not required, it will be helpful to the embedding application to be
                notified when your customer successfully saves a gift message. Knowing that will
                give you an opportunity to add an item to the shopping cart to charge your customer
                for the gift messaging if you choose to do so.
              </Text>
              <br />
              <Text>
                In order to be notified that the user has submitted a gift message, your embedding
                page can register an event listener for the 'gl_token_ready' event.
              </Text>
              <CodeBlock text="window.addEventListener('gl_token_ready', your_handler);" />
              <Text>
                The details of what your event handler does is application specific, but generally
                the event handler will at least record the GiftLift token with the shopping cart.
              </Text>
              <br />
              <Heading id="section9" sx={{ fontWeight: 500, fontSize: "1.1rem" }} py={3} as="h4">
                Storing the GiftLift Token
              </Heading>
              <Text>
                Upon receiving the gl_token_ready event, you should obtain and store the GiftLift
                token that uniquely identifies the gift message and card selection associated with
                the current shopping cart.
              </Text>
              <br />
              <br />
              <Text>
                There can be at most one gift message associated with a customer order and, as noted
                earlier, any edits to a gift message will continue to be identified by the same
                GiftLift token.
              </Text>
              <br />
              <br />
              <Text>You can obtain the token two ways:</Text>
              <ol>
                <li>
                  <Text>
                    Retrieve it from the 'gl_token_ready' event payload at ‘event.detail.token’.{" "}
                  </Text>
                </li>
                <li>
                  <Text>Retrieve it from the widget by calling the getMsgToken() function</Text>
                </li>
              </ol>

              <Text>
                You will want to ensure that the token is stored as part of the order data so that
                it can be submitted to UnDigital’s fulfillment API once the customer completes their
                checkout.
              </Text>
              <Heading id="section10" sx={{ fontWeight: 500, fontSize: "1.1rem" }} py={3} as="h4">
                Submit Order to Fulfilment Endpoint
              </Heading>

              <Text>
                Once your customer completes the checkout process, you will need to call the
                UnDigital fulfillment API to trigger generation of the gift card image which will
                eventually be printed in your distribution center during the pick/pack process for
                the order. The call to the fulfillment API is a POST that can be made from your
                server as part of the checkout process or asynchronously a short time after the
                checkout completes.
              </Text>
              <br />
              <br />
              <Text>
                Please refer to https://help.undigital.com/ for detailed documentation on the
                UnDigital fulfillment endpoint. However, one additional GiftLift specific detail is
                required in addition to what is documented there.
              </Text>
              <br />

              <Text>
                When your customer’s order includes a GiftLift gift card, you will need to add one
                extra key/value pair to the “audience” object in the fulfillment API request
                payload. The key should be ‘gl_token’ and the value should be the token that you
                obtained from the GiftLift widget via the ‘gl_token_ready‘ event handler or the
                getMsgToken function, for example:
              </Text>
              <CodeBlock text='"gl_token": "3af4981614b54dcaaf4231750ff9aac2"' />
              <Text>
                If your store runs on Shopify and you are using the UnDigital Shopify app, then
                please contact UnDigital for assistance.
              </Text>
              <br />
              <Heading id="section11" sx={{ fontWeight: 500, fontSize: "1.1rem" }} py={3} as="h4">
                Reference Guide
              </Heading>
              <Text>
                The GiftLift widget exposes three functions that are available for you to optionally
                use.
              </Text>
              <br />
              <br />
              <Text sx={{ fontWeight: 400 }}>getMsgToken()</Text>
              <br />
              <ul>
                <li>
                  <Text> Arguments: none</Text>
                </li>
                <li>
                  <Text>
                    Returns:{" "}
                    <code
                      style={{ background: "rgba(0,0,0,0.06)", color: "red", padding: "0.2rem" }}
                    >
                      Promise&lt;string&gt;
                    </code>
                  </Text>
                </li>
              </ul>

              <Text>
                This function will allow your Javascript application to obtain the gift message
                token, if any, from the embedded widget. The resolved promise value is the string
                token value. The rejected promise value, which will occur if the no gift message has
                been saved yet, will be an error message.
              </Text>
              <br />
              <br />
              <Text sx={{ fontWeight: 400 }}>setPrice(number)</Text>
              <ul>
                <li>
                  <Text>Arguments: float (e.g., 2.25)</Text>
                </li>
                <li>
                  <Text>
                    Returns:{" "}
                    <code
                      style={{ background: "rgba(0,0,0,0.06)", color: "red", padding: "0.2rem" }}
                    >
                      Promise&lt;number&gt;
                    </code>
                  </Text>
                </li>
              </ul>
              <Text>
                This function can be used to display the price you are charging your customer for
                adding a gift message to their order on the GiftLift widget The resolved promise
                value is the new float price. The rejected promise value will be an error message.
                You are not required to use this function to set and display the price that you will
                charge customers for a gift message, but we highly recommend that you clearly
                display the gift message price to your customers somewhere in the shopping cart.
              </Text>
              <br />
              <br />
              <Text sx={{ fontWeight: 400 }}>setReferences(array)</Text>
              <br />
              <ul>
                <li>
                  <Text> Arguments: array of string (e.g., [‘80201’, ‘gold’])</Text>
                </li>
                <li>
                  <Text>
                    Returns:{" "}
                    <code
                      style={{ background: "rgba(0,0,0,0.06)", color: "red", padding: "0.2rem" }}
                    >
                      Promise&lt;string&gt;
                    </code>
                  </Text>
                </li>
              </ul>

              <Text>
                This function can be used to set a list of String tag values on the GiftLift widget
                that get stored with gift messages. The resolved promise value will be the new array
                of tag values. The rejected promise value will be an error message. You can use this
                facility to tag gift messages with useful values like customer id, cart id, etc. so
                that later you can search for all gift messages matching certain tag values.
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default IntegragrionGuide
