import { SignUpDto } from "@common/interfaces"
import { Flex, Box, Image, Heading, Label, Input, Button, Text } from "theme-ui"
import Link from "@components/Link/Link"
import Logo from "@assets/logo.png"
import { useForm, SubmitHandler } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useAppDispatch } from "@store/store"
import { signUp } from "@store/slices/auth/actions"
import { ErrorMessage } from "@hookform/error-message"

const signUpSchema = yup.object().shape({
  user: yup.object({
    firstname: yup.string(),
    lastname: yup.string(),
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .required("Required field")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@?#$%^&*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    password_confirmation: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
  }),
  company: yup.object({
    name: yup.string().required("Company Name is required"),
  }),
  origins: yup.array(),
})

function SignUpForm() {
  const dispath = useAppDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<SignUpDto>({
    resolver: yupResolver(signUpSchema),
    defaultValues: {
      origins: [],
      user: {
        firstname: "firstname",
        lastname: "lastname",
      },
    },
  })

  const onSubmit: SubmitHandler<SignUpDto> = (data) => {
    dispath(signUp(data))
  }

  return (
    <Flex
      style={{
        justifyContent: "center",
        flex: 1,
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Image mt={4} width="140px" src={Logo} />
      </Box>
      <Flex
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Flex
          as="form"
          sx={{
            maxWidth: "420px",
            width: "100%",
            padding: "2rem",
            flexDirection: "column",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Heading mb={4} sx={{ fontWeight: 700, textAlign: "center" }}>
            Sign Up
          </Heading>
          <Box mb={3}>
            <Label>Email</Label>
            <Input {...register("user.email")} mt={2} mb={2} onChange={() => clearErrors()} />
            <Text color="#e74c3c">
              <ErrorMessage errors={errors} name="user.email" />
            </Text>
          </Box>
          <Box mb={3}>
            <Label>Company Name</Label>
            <Input {...register("company.name")} mt={2} mb={2} onChange={() => clearErrors()} />
            <Text color="#e74c3c">
              <ErrorMessage errors={errors} name="company.name" />
            </Text>
          </Box>

          <Box mb={3}>
            <Label>Password</Label>
            <Input
              {...register("user.password")}
              type="password"
              mt={2}
              mb={2}
              onChange={() => clearErrors()}
            />
            <Text color="#e74c3c">
              <ErrorMessage errors={errors} name="user.password" />
            </Text>
          </Box>

          <Box mb={3}>
            <Label>Confirm Password</Label>
            <Input
              {...register("user.password_confirmation")}
              type="password"
              mt={2}
              mb={2}
              onChange={() => clearErrors()}
            />
            <Text color="#e74c3c">
              <ErrorMessage errors={errors} name="user.password_confirmation" />
            </Text>
          </Box>

          <Button sx={{ height: "54px" }} mt={2}>
            Sign Up
          </Button>
        </Flex>
      </Flex>
      <Box m={4} sx={{ textAlign: "center" }}>
        <Text>
          Already have an account?
          <br />
        </Text>
        <Link variant="button" to="/sign-in">
          Sign In
        </Link>
      </Box>
    </Flex>
  )
}

export default SignUpForm
