import { updateTemplateById } from "@store/slices/occasions/actions"
import { useAppDispatch } from "@store/store"
import { Template } from "@common/interfaces"
import {
  selectSelectedCategory,
  selectSelectedCategoryId,
  selectTemplates,
} from "@store/slices/occasions/selectors"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Box, Flex, Switch, Image, Text, Card } from "theme-ui"

function Templates() {
  const [templates, setTemplates] = useState<Template[]>([])
  const selectedCategoryId = useSelector(selectSelectedCategoryId)
  const allTemplates = useSelector(selectTemplates)
  const dispatch = useAppDispatch()
  const selectedCategory = useSelector(selectSelectedCategory)
  useEffect(() => {
    const filteredTemplates = allTemplates.filter((al) => al.categoryId === selectedCategoryId)
    setTemplates(filteredTemplates)
  }, [selectedCategoryId, allTemplates])

  const handleCheck = (template: Template) => {
    dispatch(updateTemplateById(template))
  }
  return (
    <Flex sx={{ flex: 1 }}>
      <Box
        sx={{
          display: "grid",
          flex: "1",
          gap: "2rem",
          padding: ["2rem 1rem", "2rem 2rem", "2rem 3rem"],
          gridTemplateColumns: "repeat(auto-fill, minmax(240px,1fr))",
        }}
      >
        {templates.map((template) => (
          <Card
            key={template.id}
            sx={{
              height: "fit-content",
              margin: "0 auto",
            }}
          >
            <Image src={template.preview} />
            <Flex
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                height: "60px",
                boxShadow: (t) => `inset 0 0 0 2px ${t.colors!.hoverBackground}`,
                padding: "1rem",
              }}
            >
              <Text>{template.enabled ? "Show" : "Hide"}</Text>
              <Flex>
                <Switch
                  disabled={!selectedCategory?.enabled}
                  onChange={(e) => {
                    handleCheck({ ...template, enabled: !template.enabled })
                  }}
                  checked={template.enabled}
                />
              </Flex>
            </Flex>
          </Card>
        ))}
      </Box>
    </Flex>
  )
}

export default Templates
