import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { FormEvent, useState, useEffect } from "react"
import { Flex, Box, Image } from "theme-ui"
import { ResetPasswordDto } from "@common/interfaces"
import { PasswordChangeStatus } from "@common/constants"
import { useAppDispatch } from "@store/store"
import { resetPassword, verifyResetToken } from "@store/slices/auth/actions"
import { selectPasswordChangeStatus } from "@store/slices/auth/selectors"
import PasswordForm from "./PasswordForm"
import Logo from "@assets/logo.png"

function useQuery(name: string) {
  const queryParams = new URLSearchParams(useLocation().search)
  return queryParams.get(name) as string
}

function Password() {
  let token = useQuery("t")
  const history = useHistory()
  const passwordChangeStatus = useSelector(selectPasswordChangeStatus)
  const dispatch = useAppDispatch()
  const [params, setParams] = useState<ResetPasswordDto>({
    token: "",
    pw: "",
    pw_confirm: "",
  })

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    dispatch(resetPassword({ ...params, token }))
  }

  useEffect(() => {
    if (token) {
      dispatch(verifyResetToken({ token }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    if (passwordChangeStatus === PasswordChangeStatus.SUCCESS) {
      history.push("/sign-in")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordChangeStatus])

  return (
    <Flex sx={{ height: "100vh" }}>
      <Box sx={{ display: ["none", "none", "flex"], flex: 1.2 }}>
        <Flex
          sx={{
            bg: "primary",
            flex: 1.2,
          }}
        ></Flex>
      </Box>
      <Flex
        style={{
          justifyContent: "center",
          flex: 1,
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Image mt={4} width="140px" src={Logo} />
        </Box>
        {
          {
            [PasswordChangeStatus.LOADING]: <Loading />,
            [PasswordChangeStatus.SUCCESS]: <Loading />,
            [PasswordChangeStatus.INVALID]: <InvalidToken />,
            [PasswordChangeStatus.LOADED]: (
              <PasswordForm params={params} setParams={setParams} onSubmit={handleSubmit} />
            ),
          }[passwordChangeStatus]
        }
      </Flex>
    </Flex>
  )
}

function Loading() {
  return <div>Loading</div>
}

function InvalidToken() {
  return <div>error token</div>
}
export default Password
