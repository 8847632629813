import { getCurrentDate } from "@/utils/date"
import { Company, UpdateObjectDto } from "@common/interfaces"
import { createAsyncThunk, createAction } from "@reduxjs/toolkit"
import api from "@services/api"
import { AxiosError } from "axios"
import { setErrors, setNotification } from "../feedback/actions"

export const setCompany = createAction<Partial<Company>>("details/setCompany")
export const setCompanyOrigins = createAction<string[]>("details/setCompanyOrigins")
export const setEnabledCompanyId = createAction<number>("details/setEnabledCompanyId")
export const unsetCompany = createAction("details/unsetCompany")
export const setUpdatedAt = createAction<number>("details/setUpdatedAt")
export const setCompanyGLWidgetID = createAction<string>("details/setCompanyGLWidgetID")
export const setPrice = createAction<string>("details/setPrice")

export const getCurrentCompany = createAsyncThunk<
  void,
  never,
  { rejectValue: Record<string, string[]> }
>("details/getCurrentCompany", async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.getCurrentCompany()
    dispatch(setCompany(response.company))
    dispatch(setEnabledCompanyId(response.enabledCompanyId))
    dispatch(setCompanyGLWidgetID(response.glWidgetID))
  } catch (err) {
    return rejectWithValue((err as AxiosError).response?.data?.error.data || null)
  }
})

export const updateCompanyOrigins = createAsyncThunk<
  void,
  UpdateObjectDto<Company>,
  { rejectValue: string }
>("details/updateCompanyOrigins", async (args, { rejectWithValue, dispatch }) => {
  try {
    await api.updateCompanyOrigins(args.id, { origins: args.props.origins })
    dispatch(setCompanyOrigins(args.props.origins!))
    dispatch(setUpdatedAt(getCurrentDate()))
  } catch (err) {
    const payload = (err as AxiosError).response?.data?.error.data
    const errorMessages = Object.keys(payload).map((key) => key + ": " + payload[key])
    dispatch(setErrors(errorMessages))
    dispatch(
      setNotification({
        type: "error",
        message: errorMessages[0] ? errorMessages[0] : "Something went wrong",
      })
    )
    return rejectWithValue((err as AxiosError).response?.data?.error || null)
  }
})

export const updateCompany = createAsyncThunk<
  void,
  UpdateObjectDto<Company>,
  { rejectValue: string }
>("details/updateCompany", async (args, { rejectWithValue, dispatch }) => {
  try {
    await api.updateCompany(args.id, args.props)
    dispatch(setCompany(args.props))
    dispatch(setUpdatedAt(getCurrentDate()))
  } catch (err) {
    return rejectWithValue((err as AxiosError).response?.data?.error || null)
  }
})
