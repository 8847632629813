import { createReducer } from "@reduxjs/toolkit"
import { setNotification } from "./actions"

export interface FeedbackNotification {
  message: string
  type: NotificationType
}

export interface FeedbackState {
  notification: FeedbackNotification
  errors: string[]
}

type NotificationType = "success" | "error" | "danger" | "default"

const initialState: FeedbackState = {
  notification: {
    message: "",
    type: "default",
  },
  errors: [],
}

export const feedbackReducer = createReducer(initialState, (builder) => {
  builder.addCase(setNotification, (state, { payload }) => {
    state.notification = payload
  })
})
