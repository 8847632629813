import React, { useEffect } from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
  Redirect,
} from "react-router-dom"
import { useSelector } from "react-redux"
import { selectIsAuthenticated } from "@store/slices/auth/selectors"
import { useTokenInterceptor } from "@hooks/useTokenInterceptor"
import { useAppDispatch } from "@store/store"
import { getCategories, getTemplates } from "@store/slices/occasions/actions"

import SignIn from "@scenes/Auth/SignIn"
import SignUp from "@scenes/Auth/SignUp"
import Password from "@scenes/Auth/Password"
import ResetPassword from "@scenes/Auth/ResetPassword"
import Installation from "@/scenes/Installation"
import Settings from "@scenes/Settings"
import Occasions from "@scenes/Occasions"
import IntegrationGuide from "@scenes/IntegrationGuide"
interface IProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  exact?: boolean
  path?: string | string[]
  render?: (props: RouteComponentProps<any>) => React.ReactNode
  isAuthenticated: boolean
}

const Routes = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isLoaded = useTokenInterceptor()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCategories())
      dispatch(getTemplates())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  if (!isLoaded) {
    return null
  }
  return (
    <Router>
      <Switch>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/settings" component={Settings} />
        <PublicRoute isAuthenticated={isAuthenticated} path="/password" component={Password} />
        <PublicRoute
          isAuthenticated={isAuthenticated}
          path="/reset-password"
          component={ResetPassword}
        />
        <PublicRoute isAuthenticated={isAuthenticated} path="/sign-in" component={SignIn} />
        <PublicRoute isAuthenticated={isAuthenticated} path="/sign-up" component={SignUp} />
        <PrivateRoute isAuthenticated={isAuthenticated} path="/occasions" component={Occasions} />
        <PrivateRoute
          isAuthenticated={isAuthenticated}
          path="/installation"
          component={Installation}
        />

        <PrivateRoute
          isAuthenticated={isAuthenticated}
          path="/integration-guide"
          component={IntegrationGuide}
        />
        <Route exact path="/">
          {isAuthenticated ? <Redirect to="/occasions" /> : <Redirect to="/sign-in" />}
        </Route>
      </Switch>
    </Router>
  )
}

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }: IProps) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
            }}
          />
        )
      }
    />
  )
}

const PublicRoute = ({ component: Component, isAuthenticated, ...rest }: IProps) => {
  if (!Component) return null
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

export default Routes
