import { useState } from "react"
import { useSelector } from "react-redux"
import { Flex, Input, Text, Switch, Box } from "theme-ui"
import { useAppDispatch } from "@store/store"
import { setCategorySelectedId, updateCategoryById } from "@store/slices/occasions/actions"
import { selectCategories } from "@store/slices/occasions/selectors"
import { Category } from "@common/interfaces"
import useAppContext from "@/hooks/useAppContext"
import SearchIcon from "@components/Icons/Search"
import AngleRightIcon from "@components/Icons/AngleRight"

function Categories() {
  const dispatch = useAppDispatch()
  const [searchQuery, setSearchQuery] = useState("")
  const { setContextMenu, contextMenu, isMobile } = useAppContext()
  const categories = useSelector(selectCategories)
  const handleCheck = (category: Category) => {
    dispatch(updateCategoryById(category))
  }

  const handleChange = (id: number) => {
    setContextMenu(id)
    dispatch(setCategorySelectedId(id))
  }
  return (
    <Flex
      sx={{
        flex: 1,
        flexDirection: "column",
        maxWidth: [null, null, null, 600],
        padding: ["0.5rem 1rem", "0.5rem 2rem", "0.5rem 3rem"],
      }}
    >
      <Box>
        <Flex mb="1rem" sx={{ position: "relative", alignItems: "center" }}>
          <Flex sx={{ position: "absolute", left: "16px" }}>
            <SearchIcon size={18} />
          </Flex>
          <Input
            placeholder="Search Occassions"
            pl="3rem"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Flex>
        {categories
          .filter(
            (category) =>
              category.name.toLocaleLowerCase().search(searchQuery.toLocaleLowerCase()) > -1
          )
          .map((category, index) => (
            <Flex
              key={category.id}
              sx={{
                justifyContent: "space-between",
                height: "4rem",
                padding: "0 1rem",
                cursor: "pointer",
                bg: (t) =>
                  ` ${
                    !isMobile
                      ? contextMenu === category.id || (index === 0 && contextMenu === null)
                        ? t.colors!.hoverBackground
                        : t.colors!.background
                      : "none"
                  }`,
                ":hover": {
                  bg: "hoverBackground",
                },
              }}
            >
              <Flex
                onClick={() => handleChange(category.id)}
                sx={{ flex: 1, alignItems: "center" }}
              >
                <Text>{category.name}</Text>
              </Flex>
              <Flex sx={{ alignItems: "center", paddingRight: "2rem" }}>
                <Switch
                  onChange={(e) => {
                    handleCheck({ ...category, enabled: !category.enabled })
                  }}
                  checked={category.enabled}
                />
              </Flex>
              <Flex
                onClick={() => handleChange(category.id)}
                sx={{ alignItems: "center", color: "primary" }}
              >
                <AngleRightIcon size={18} />
              </Flex>
            </Flex>
          ))}
      </Box>
    </Flex>
  )
}

export default Categories
