import { Flex } from "theme-ui"
import Categories from "./Categories"
import Description from "./Description"
import Templates from "./Templates"

function Web() {
  return (
    <Flex sx={{ flex: 1, flexDirection: "column" }}>
      <Description />
      <Flex>
        <Categories />
        <Templates />
      </Flex>
    </Flex>
  )
}

export default Web
