import { Flex, Box } from "theme-ui"
import SignInForm from "./SignInForm"

function SignIn() {
  return (
    <Flex sx={{ height: "100vh" }}>
      <Box sx={{ display: ["none", "none", "flex"], flex: 1.2 }}>
        <Flex
          sx={{
            bg: "primary",
            flex: 1.2,
          }}
        ></Flex>
      </Box>
      <SignInForm />
    </Flex>
  )
}

export default SignIn
